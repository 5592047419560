import { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, Image, Platform } from "react-native";
import { Asset } from "expo-asset";
import * as SplashScreen from "expo-splash-screen";
import Ads from "@lib/ads";
import * as Sound from "@lib/sound";
import Storage from "@lib/storage";
import Api from "@lib/api";
import features from "@lib/features";
import * as Updates from "expo-updates";

const assets = [
  require("@assets/spritesheet.png"),
  require("@assets/sounds/jump.mp3"),
  require("@assets/sounds/jumpHigh.mp3"),
  require("@assets/sounds/wood.mp3"),
  require("@assets/sounds/woodBreak.mp3"),
  require("@assets/sounds/cloud.mp3"),
  require("@assets/sounds/ice.mp3"),
  require("@assets/sounds/gameOver.mp3"),
  require("@assets/music/Binary_Heavens.mp3"),
  require("@assets/music/Nintendo_Revolution.mp3"),
  require("@assets/music/Helios.mp3"),
];

export default function App({ children }) {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await SplashScreen.preventAutoHideAsync();

        await Api.init();
        await features.init();
        // await Storage.clear();
        await Storage.init({
          settings: {
            soundEnabled: true,
            controls: features.accelerometer ? "rotate" : "touch",
            tutorialPassedFor: [],
          },
          bestScore: 0,
        });
        await Asset.loadAsync(assets);
        await Ads.init({
          show: () => Platform.OS !== "android" && Sound.muteMusic(true),
          close: () => Platform.OS !== "android" && Sound.muteMusic(false),
        });

        await new Promise((resolve) =>
          setTimeout(resolve, Platform.select({ android: 0, default: 2000 }))
        );
        setAppIsReady(true);
      } catch (e) {
        console.error(e);
        alert(e);
        Platform.OS === "web" ? location.reload() : Updates.reloadAsync();
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    appIsReady && (await SplashScreen.hideAsync());
  }, [appIsReady]);

  if (!appIsReady) {
    return (
      <Image
        style={styles.image}
        source={require("@assets/splash.png")}
        resizeMode="contain"
      />
    );
  }

  return (
    <View style={styles.container} onLayout={onLayoutRootView}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  image: {
    flex: 1,
    width: "100%",
    backgroundColor: "#000",
  },
});
