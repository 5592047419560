import * as Haptics from "expo-haptics";
import { Platform } from "react-native";
import Settings from "./Settings";
import PhysicsNode from "./PhysicsNode";
import * as Sound from "@lib/sound";

class Player extends PhysicsNode {
  get left() {
    return this.x - this.width / 2;
  }
  get right() {
    return this.x + this.width / 2;
  }

  constructor({
    game,
    sounds,
    app: {
      renderer: { width, height },
    },
    texture,
  }) {
    super(texture);
    this.game = game;
    this.sounds = sounds;
    this.screenSize = { width, height };
    this.heightRatio = height / 2532;
    this.reset();
  }

  reset() {
    super.reset();
    const { width, height } = this.screenSize;
    this.anchor.x = 0.5;
    this.x = width / 2;
    this.y = height / 2;
  }

  update(dt) {
    super.update(dt);
    this.velocity.y += dt * Settings.gravity * this.heightRatio;
    // this.velocity.y = -3;
    // console.log(this.velocity.y);

    // To avoid jitter
    if (Math.abs(this.velocity.x) > 1) {
      this.scale.x = this.velocity.x < 0 ? -Settings.scale : Settings.scale;
    }

    //Make the player move through walls
    if (this.left > this.screenSize.width) {
      this.x = this.width * -0.5;
    } else if (this.right < 0) {
      this.x = this.screenSize.width;
    }

    let scale = Math.abs(this.velocity.y) / 20;
    if (scale < 1) scale = 1;
    if (scale > 6) scale = 6;
    this.scale.y = Settings.scale / scale;
  }

  jump = (dt, platform) => {
    // Haptics.selectionAsync();
    // Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    // Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    // Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);

    if (this.game.settings.soundEnabled)
      switch (platform.type) {
        case "moving":
          Sound.play("cloud");
          break;
        case "breakable":
          if (platform.jumpCount === 1) Sound.play("wood");
          else Sound.play("woodBreak");
          break;
        case "vanishable":
          Sound.play("ice");
          break;
        default:
          Sound.play("jump");
      }
    if (Platform.OS !== "web")
      switch (platform.type) {
        case "moving":
          Haptics.selectionAsync();
          break;
        case "breakable":
          if (platform.jumpCount === 1)
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
          else
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
          break;
        case "vanishable":
          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
          break;
        default:
          // console.log("jump", platform.type, platform.jumpCount);
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
      }
    this.velocity.y = -Settings.jumpVelocity * this.heightRatio; // * -Settings.scale;
  };

  jumpHigh = (dt) => {
    if (this.game.settings.soundEnabled) Sound.play("jumpHigh");
    if (Platform.OS !== "web") {
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
      setTimeout(() => {
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
      }, 200);
    }
    // console.log("jumpHigh");
    this.velocity.y = -Settings.springVelocity * this.heightRatio; //* -Settings.scale;
  };
}
export default Player;
