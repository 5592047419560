import "@lib/metrics";
import SplashScreen from "@ui/SplashScreen";
import ErrorCatch from "@ui/ErrorCatch";
import UI from "@ui";

export default function App() {
	return (
		<ErrorCatch>
			<SplashScreen>
				<UI />
			</SplashScreen>
		</ErrorCatch>
	);
}
