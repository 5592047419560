import * as React from "react";
import Svg, { SvgProps, G, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508.514 508.514"
    style={{
      enableBackground: "new 0 0 508.514 508.514",
    }}
    xmlSpace="preserve"
    width={36}
    height={36}
    {...props}
  >
    <G fill="#FFF">
      <Path d="M271.483.109c-5.784-.54-12.554.858-20.531 5.689 0 0-132.533 115.625-138.286 121.314H39.725c-17.544.032-31.782 14.27-31.782 31.814v194.731c0 17.607 14.239 31.782 31.782 31.782h72.941c5.753 5.753 138.286 117.277 138.286 117.277 7.977 4.799 14.747 6.229 20.531 5.689 11.76-1.112 20.023-10.965 22.534-21.358.127-1.017.127-464.533-.032-465.55-2.479-10.423-10.774-20.275-22.502-21.388zM342.962 309.798c-7.85 3.973-10.997 13.508-7.087 21.358 2.829 5.53 8.422 8.74 14.207 8.74 2.384 0 4.799-.572 7.151-1.684 32.132-16.209 52.091-48.341 52.091-83.938s-19.959-67.728-52.091-83.938c-7.85-3.973-17.385-.795-21.358 7.056-3.909 7.85-.763 17.385 7.087 21.358 21.326 10.743 34.579 32.005 34.579 55.524s-13.253 44.781-34.579 55.524z" />
      <Path d="M339.72 59.32c-8.486-1.716-17.004 3.941-18.593 12.522-1.716 8.645 3.909 17.004 12.522 18.688 78.312 15.256 135.139 84.128 135.139 163.743S411.962 402.761 333.65 418.017c-8.613 1.684-14.239 10.011-12.554 18.656 1.494 7.596 8.136 12.84 15.542 12.84l3.083-.318c93.218-18.148 160.851-100.147 160.851-194.922S432.938 77.5 339.72 59.32z" />
    </G>
  </Svg>
);

export default SvgComponent;
