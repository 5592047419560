import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    {...props}
    width={36}
    height={36}
  >
    <Path
      d="M100 12a88 88 0 1 0 88 88 88 88 0 0 0-88-88Zm-70.4 88A70.38 70.38 0 0 1 100 29.6a69.53 69.53 0 0 1 43.12 14.87l-34.37 34.37a19.5 19.5 0 0 0-9.56-2.33H82.6V105l-6.72 6.72-11.39-35.21H53l-14.79 45.93h10.73l2.58-8.93H66l1.83 6.25-23.36 23.36A69.53 69.53 0 0 1 29.6 100Zm77.92-4.72v8.33a11.73 11.73 0 0 1-2.4 7.71 8.14 8.14 0 0 1-6.66 2.92h-5.17V84.71h5.17a8.18 8.18 0 0 1 6.66 2.9 11.68 11.68 0 0 1 2.4 7.67Zm-43.88 10h-9.75l4.76-16.43h.19ZM100 170.4a69.53 69.53 0 0 1-43.12-14.87L90 122.44h9.22a18.74 18.74 0 0 0 13.57-5.27 18 18 0 0 0 5.45-13.56v-9.37l6.6-6.61c0 .37-.06.74-.06 1.12q0 5.93 4.2 9.07t12.9 5.88a20.53 20.53 0 0 1 5.77 2.81 4.58 4.58 0 0 1 1.61 3.76 4.28 4.28 0 0 1-1.62 3.47 7.75 7.75 0 0 1-4.94 1.32q-4.35 0-6.5-1.7t-2.2-5.68h-10.39l-.07.19q-.15 7.38 5.56 11.31a23.24 23.24 0 0 0 13.53 3.92q7.78 0 12.51-3.42a11.06 11.06 0 0 0 4.71-9.48 11.74 11.74 0 0 0-3.95-9.37q-4-3.4-11.79-5.74a37.27 37.27 0 0 1-6.92-3 3.72 3.72 0 0 1-1.85-3.24 4.3 4.3 0 0 1 1.83-3.5 8.1 8.1 0 0 1 5.05-1.42 7.31 7.31 0 0 1 5.14 1.72 5.82 5.82 0 0 1 1.86 4.47h10.35l.06-.19a11.94 11.94 0 0 0-4.71-10.11q-4.87-3.93-12.51-3.93a26.12 26.12 0 0 0-6.65.8l19.77-19.77A69.53 69.53 0 0 1 170.4 100a70.38 70.38 0 0 1-70.4 70.4Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
);

export default SvgComponent;
