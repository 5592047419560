import { Share, Platform } from "react-native";
import l from "@lib/lang";
import i18n from "i18n-js";

function parseQuery(queryString) {
	var query = {};
	var pairs = (queryString[0] === "?"
		? queryString.substr(1)
		: queryString
	).split("&");
	for (var i = 0; i < pairs.length; i++) {
		var pair = pairs[i].split("=");
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
	}
	return query;
}

class Api {
	async init() {
		if (Platform.OS === "web") {
			const query = parseQuery(location.search);
			if (query.platform) {
				Platform.vk = query;
				return;
			}

			await YaGames.init().then((ysdk) => {
				const { environment } = ysdk;
				if (environment.app.id) {
					Platform.ya = environment;
					window.ysdk = ysdk;
					i18n.defaultLocale = "ru";
					i18n.locale = environment.i18n.lang;
				}
			});
		}
	}

	async share({ bestScore }) {
		const url = "https://monkeyjump.epicstar.io";
		await Share.share({
			message: l("shareText", { count: bestScore }) + "\n" + url,
		});
	}

	async addToFavorites() {
		alert("not supported");
	}

	async showLeaderboard() {
		alert("not supported");
	}

	async invite() {
		alert("not supported");
	}

	async removeAds() {
		alert("not supported");
	}

	async submitBestScore({ score }) {}
}

export default Api;
