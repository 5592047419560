import { Component, useEffect } from "react";

import Fallback from "./Fallback";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	componentDidCatch(error, info) {
		this.setState({ error, info });
	}

	render() {
		if (this.state.error) return <Fallback {...this.state} />;

		return this.props.children;
	}
}

export default function ({ children }) {
	return (
		<ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
	);
}
