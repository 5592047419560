import { Platform } from "react-native";
import Features from "./Features";

class FeaturesWeb extends Features {
	favorites = true;
	leaderboard = Platform.vk && Platform.vk.platform !== "web";
	invite = Platform.vk;

	async init() {
		await super.init();
		this.accelerometer = false;
		if (Platform.vk) this.share = true;
		if (Platform.ya) {
			this.leaderboard = false;
			this.favorites = await ysdk.shortcut
				.canShowPrompt()
				.then((result) => result.canShow);
			this.storeReview = await ysdk.feedback
				.canReview()
				.then(({ value }) => value);
		}
	}
}

export default new FeaturesWeb();
