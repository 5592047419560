import { useEffect } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";

import { useStorage } from "@lib/storage";
import features from "@lib/features";
import Api from "@lib/api";
import l from "@lib/lang";

import Crown from "@icons/Crown.js";
import Share from "@icons/Share.js";

export default function BestScore({ game }) {
	const [bestScore, setBestScore] = useStorage("bestScore", 0);

	useEffect(() => {
		if (game.score > bestScore) {
			setBestScore(game.score);
			Api.submitBestScore({ score: game.score });
		}
	}, [bestScore]);

	return (
		<View style={styles.container}>
			<View style={styles.bestScore}>
				<Crown />
				<Text style={styles.bestScoreText}>{bestScore}</Text>
			</View>
			{features.share && (
				<TouchableOpacity
					style={styles.share}
					onPress={() => Api.share({ bestScore })}
				>
					<Share />
					<Text style={styles.shareText}>{l("share")}</Text>
				</TouchableOpacity>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
	},
	bestScore: {
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
	},
	bestScoreText: {
		fontSize: 36,
		fontWeight: "200",
		color: "white",
		marginLeft: 8,
	},
	share: {
		marginTop: 16,
		alignItems: "center",
	},
	shareText: {
		color: "white",
		fontSize: 17,
		marginTop: 8,
	},
});
