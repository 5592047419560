import { memo } from "react";
import { StyleSheet, View, Text, Animated, SafeAreaView } from "react-native";
import { useAnimation } from "react-native-animation-hooks";

export default memo(
	function Score({ score }) {
		const opacity = useAnimation({
			type: "timing",
			duration: score ? 300 : 0,
			initialValue: 0,
			toValue: score ? 1 : 0,
			useNativeDriver: true,
		});

		return (
			<Animated.View style={[styles.container, { opacity }]}>
				<SafeAreaView style={styles.safe}>
					<Text style={styles.scoreText}>{score}</Text>
				</SafeAreaView>
			</Animated.View>
		);
	},
	(a, b) => {
		return a.score === b.score;
	}
);

const styles = StyleSheet.create({
	container: {
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: 36,
		position: "absolute",
	},
	safe: {
		alignItems: "center",
	},
	scoreText: {
		fontSize: 60,
		fontWeight: "200",
		color: "white",
		marginTop: 16,
	},
});
