import { Platform } from "react-native";

import * as Localization from "expo-localization";
import i18n from "i18n-js";

i18n.pluralization["ru"] = function (count) {
	var key =
		count % 10 == 1 && count % 100 != 11
			? "one"
			: [2, 3, 4].indexOf(count % 10) >= 0 &&
			  [12, 13, 14].indexOf(count % 100) < 0
			? "few"
			: count % 10 == 0 ||
			  [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 ||
			  [11, 12, 13, 14].indexOf(count % 100) >= 0
			? "many"
			: "other";
	return [key];
};

i18n.missingTranslation = function (key, props) {
	if (props.scope) return `\{${props.scope}.${key}\}`;
	return key;
};
i18n.defaultLocale = "en";
// i18n.fallbacks = true;
// i18n.missingBehaviour = "guess";

i18n.translations = {
	en: require("@langs/en"),
	ru: require("@langs/ru"),
};

const init = () => {
	let prefLangs = Localization.locales;
	if (Platform.vk && Platform.vk.language === "0")
		prefLangs = ["ru", ...prefLangs];

	for (let locale of prefLangs) {
		const lang = Object.keys(i18n.translations).find((_) =>
			new RegExp(`^${_}`).test(locale)
		);
		if (lang) {
			i18n.locale = lang;
			return;
		}
	}
};

init();

export default i18n.t;
