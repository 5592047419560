import { useCallback } from "react";
import { TouchableWithoutFeedback, useWindowDimensions } from "react-native";

export default function TouchControl({ children, onDirection }) {
	const { width } = useWindowDimensions();
	const center = width / 2;
	const onPressIn = useCallback(
		({ nativeEvent: { locationX } }) => {
			onDirection(locationX > center ? 1 : locationX < center ? -1 : 0);
		},
		[center]
	);
	const onPressOut = useCallback(({ nativeEvent }) => {
		onDirection(0);
	}, []);

	return (
		<TouchableWithoutFeedback
			touchSoundDisabled
			onPressIn={onPressIn}
			onPressOut={onPressOut}
		>
			{children}
		</TouchableWithoutFeedback>
	);
}
