import { Accelerometer } from "expo-sensors";
import * as Sharing from "expo-sharing";
import * as StoreReview from "expo-store-review";

class Features {
	accelerometer = false;
	share = false;
	storeReview = false;
	removeAds = false;
	favorites = false;
	leaderboard = false;
	invite = false;

	async init() {
		this.accelerometer = await Accelerometer.isAvailableAsync();
		this.share = await Sharing.isAvailableAsync();
		this.storeReview = await StoreReview.isAvailableAsync();
	}
}

export default Features;
