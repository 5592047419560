import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 472.615 472.615"
    style={{
      enableBackground: "new 0 0 472.615 472.615",
    }}
    xmlSpace="preserve"
    width={32}
    height={32}
    {...props}
  >
    <Path
      d="M436.475 103.262c-19.961 0-36.141 16.18-36.141 36.141 0 9.776 3.92 18.613 10.226 25.12l-74.195 71.928-76.969-122.068c12.207-7.695 20.378-21.202 20.378-36.7 0-24.005-19.46-43.467-43.466-43.467-24.005 0-43.465 19.462-43.465 43.467 0 15.468 8.139 28.961 20.31 36.666L136.215 236.45 62.05 164.528c6.311-6.505 10.232-15.346 10.232-25.125 0-19.961-16.181-36.141-36.141-36.141S0 119.442 0 139.403c0 19.959 16.181 36.139 36.141 36.139 2.196 0 4.322-.272 6.411-.647l34.27 166.474h318.972l34.27-166.474c2.088.378 4.215.647 6.412.647 19.96 0 36.14-16.18 36.14-36.139-.001-19.961-16.181-36.141-36.141-36.141zM391.714 361.061H80.875l-18.767 77.337h348.373z"
      fill="#FFF"
    />
  </Svg>
);

export default SvgComponent;
