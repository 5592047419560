import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={41}
    height={85}
    viewBox="0 0 164 338"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M121.471 175.085H42.529c-23.052 0-41.806 18.754-41.806 41.806v78.942c0 23.052 18.754 41.806 41.806 41.806h78.942c23.052 0 41.806-18.754 41.806-41.806v-78.942c0-23.051-18.754-41.806-41.806-41.806Zm26.806 120.748c0 14.781-12.025 26.806-26.806 26.806H42.529c-14.781 0-26.806-12.025-26.806-26.806v-78.942c0-14.781 12.025-26.806 26.806-26.806h78.942c14.781 0 26.806 12.025 26.806 26.806v78.942ZM121.47.36H42.53C19.501.36.745 19.1.72 42.132a7.5 7.5 0 0 0 7.492 7.508h.008a7.5 7.5 0 0 0 7.5-7.492c.016-14.77 12.042-26.788 26.81-26.788h78.94c14.783 0 26.81 12.027 26.81 26.81v78.94c0 14.783-12.027 26.81-26.81 26.81H42.53c-14.783 0-26.81-12.027-26.81-26.81V72.1a7.5 7.5 0 0 0-15 0v49.01c0 23.054 18.756 41.81 41.81 41.81h78.94c23.054 0 41.81-18.756 41.81-41.81V42.17c0-23.054-18.756-41.81-41.81-41.81Z"
      fill="#fff"
    />
    <Path
      d="M64.038 229.227c-6.903-2.546-13.335-5.318-15.382-6.213-1.343-1.114-1.296-2.646-1.204-3.29.133-.925.811-3.188 4.232-4.219 7.782-2.342 15.033 3.364 15.25 3.538a7.5 7.5 0 0 0 9.61-11.517c-.542-.456-13.472-11.121-29.187-6.384-7.989 2.407-13.642 8.71-14.753 16.45-1.044 7.271 2.109 14.267 8.228 18.256.332.216.679.405 1.041.566a309.21 309.21 0 0 0 16.974 6.884c1.868.689 7.904 3.25 7.185 7.278-.537 3.009-4.245 6.121-9.519 6.121-5.53 0-10.847-2.225-14.224-5.951a7.501 7.501 0 0 0-11.115 10.073c6.265 6.913 15.501 10.878 25.339 10.878 12.159 0 22.373-7.774 24.285-18.484 1.443-8.074-2.203-18.617-16.76-23.986ZM85.052 31.96a7.5 7.5 0 0 0-8.82 5.892l-4.165 20.925-6.702-21.755c-1.092-3.546-4.613-5.719-8.284-5.166-2.972.448-5.318 2.63-6.102 5.416L44.32 58.784l-4.156-21.01a7.5 7.5 0 1 0-14.715 2.911l9.132 46.168c.071.359.168.712.291 1.057a8.563 8.563 0 0 0 8.063 5.691h.06a8.564 8.564 0 0 0 8.105-5.999l7.091-22.91 7.059 22.914c.031.101.064.202.1.301 1.218 3.43 4.474 5.698 8.121 5.694a8.565 8.565 0 0 0 8.046-5.801c.106-.312.191-.63.256-.953l9.168-46.069a7.496 7.496 0 0 0-5.889-8.818ZM109.298 138.079a7.5 7.5 0 0 0 7.5-7.5v-26.37l7.929 7.929a7.498 7.498 0 0 0 10.606 0 7.5 7.5 0 0 0 0-10.606L114.601 80.8a7.497 7.497 0 0 0-10.606 0l-20.732 20.732a7.5 7.5 0 0 0 5.303 12.803 7.474 7.474 0 0 0 5.303-2.197l7.929-7.929v26.37a7.5 7.5 0 0 0 7.5 7.5ZM124.727 283.831l-7.929 7.929v-26.37a7.5 7.5 0 0 0-15 0v26.37l-7.93-7.929a7.5 7.5 0 0 0-10.605 0 7.5 7.5 0 0 0 0 10.606l20.732 20.732a7.497 7.497 0 0 0 10.606 0l20.732-20.732a7.5 7.5 0 0 0 0-10.606 7.5 7.5 0 0 0-10.606 0Z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
