import { Platform } from "react-native";
import bridge from "@vkontakte/vk-bridge";

export const init = async ({ show, close }) => {
	// bridge
	// 	.send("VKWebAppInit", {})
	// 	.then((e) => console.log("VKWebAppInit", e))
	// // 	.catch((e) => console.log("VKWebAppInit", e));
	// bridge
	// 	.send("VKWebAppCheckNativeAds", { ad_format: "interstitial" })
	// 	.then((e) => console.log("VKWebAppCheckNativeAds", e))
	// 	.catch((e) => console.log("VKWebAppCheckNativeAds", e));
};

export const show = async () => {
	if (Platform.vk) {
		bridge
			.send("VKWebAppShowNativeAds", { ad_format: "interstitial" })
			.then((data) => console.log(data.result))
			.catch((error) => console.log(error));
	} else if (Platform.ya) {
		ysdk?.adv.showFullscreenAdv({
			callbacks: {
				onClose: function (wasShown) {
					"ysdk ad close";
				},
				onError: function (error) {
					"ysdk ad error";
				},
			},
		});
	}
};

export default { init, show };
