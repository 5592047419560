import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path
      d="m159.648 486.88-16-74.24c-1.856-8.64-10.272-13.984-19.008-12.288-8.64 1.888-14.112 10.368-12.256 19.04l8.288 38.432C64.992 419.04 32 356.992 32 288c0-8.832-7.168-16-16-16s-16 7.168-16 16c0 74.4 34.88 144.288 92.672 189.312l-45.632 2.72c-8.832.544-15.552 8.096-15.008 16.928C32.544 505.44 39.584 512 48 512c.32 0 .64 0 .96-.032l96-5.76a16.087 16.087 0 0 0 11.808-6.272 16.017 16.017 0 0 0 2.88-13.056zM419.328 34.688l45.632-2.72c8.832-.544 15.552-8.096 15.008-16.928C479.456 6.56 472.416 0 464 0c-.32 0-.64 0-.96.032l-96 5.76a16.087 16.087 0 0 0-11.808 6.272 16.017 16.017 0 0 0-2.88 13.056l16 74.24c1.856 8.64 10.272 13.984 19.008 12.288 8.64-1.888 14.112-10.368 12.256-19.04l-8.256-38.432C447.008 92.96 480 155.008 480 224c0 8.832 7.168 16 16 16s16-7.168 16-16c0-74.4-34.88-144.288-92.672-189.312zM459.648 278.624 233.376 52.352c-12.48-12.48-32.768-12.48-45.248 0L52.352 188.128c-12.48 12.48-12.48 32.768 0 45.248l226.272 226.272c12.48 12.48 32.768 12.48 45.248 0l135.776-135.776c12.48-12.48 12.48-32.768 0-45.248zM278.624 414.4 97.6 233.376 233.376 97.6 414.4 278.624 278.624 414.4z"
      fill="#FFF"
      xmlns="http://www.w3.org/2000/svg"
    />
  </Svg>
);

export default SvgComponent;
