import PhysicsNode from "./PhysicsNode";

class Platform extends PhysicsNode {
  state = false;
  interacted = false;
  jumpCount = 0;

  constructor({ textures, type }) {
    const { tint, texture } = Platform.Styles[type];
    super(textures[texture]);
    // this.tint = tint;
    this.type = type;
    this.textures = textures;

    if (this.type === Platform.Types.moving) {
      this.velocity.x = Math.random() > 0.5 ? 1 : -1;
    }
  }

  reset() {
    super.reset();
    this.y -= 12;
  }

  update(dt) {
    super.update(dt);
    if (this.type === "breakable" && this.jumpCount >= 1) {
      // console.log(this.jumpCount);
      this.texture = this.textures.wood_broken;
    }
  }

  get canHaveSpring() {
    return (
      this.type === Platform.Types.normal || this.type === Platform.Types.moving
    );
  }
}

Platform.Types = {
  normal: "normal",
  moving: "moving",
  breakable: "breakable",
  broken: "broken",
  falling: "falling",
  vanishable: "vanishable",
};

Platform.Colors = {
  brown: 0x8b572a,
  blue: 0x158fbd,
  white: 0xffffff,
  green: 0x89c741,
};

Platform.Styles = {
  [Platform.Types.normal]: { texture: "block" },
  [Platform.Types.moving]: { texture: "cloud" },
  [Platform.Types.breakable]: {
    texture: "wood",
  },
  [Platform.Types.broken]: {
    texture: "wood_broken",
  },
  [Platform.Types.falling]: {
    texture: "wood_falling",
  },
  [Platform.Types.crashed]: {
    texture: "ice_broken",
  },
  [Platform.Types.vanishable]: {
    texture: "ice",
  },
};

Platform.Levels = [
  // [
  //   // Platform.Types.normal,
  //   Platform.Types.moving,
  //   // Platform.Types.breakable,
  //   // Platform.Types.vanishable,
  // ],
  [Platform.Types.normal],
  [Platform.Types.normal, Platform.Types.moving],
  [
    Platform.Types.normal,
    Platform.Types.normal,
    Platform.Types.moving,
    Platform.Types.breakable,
  ],
  [Platform.Types.moving, Platform.Types.breakable, Platform.Types.breakable],
  [Platform.Types.moving, Platform.Types.breakable, Platform.Types.vanishable],
  [
    Platform.Types.moving,
    Platform.Types.breakable,
    Platform.Types.vanishable,
    Platform.Types.vanishable,
  ],
];

export default Platform;
