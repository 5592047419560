import { useState, useEffect, useCallback } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { EventEmitter } from "events";

const events = new EventEmitter();
const storage = {};

export const init = (items = {}) => {
	return Promise.all(
		Object.entries(items).map(async ([key, defaultValue]) => {
			const value = await AsyncStorage.getItem(key);
			storage[key] = value ? JSON.parse(value) : defaultValue;
		})
	);
};

export const useStorage = (key, defaultValues) => {
	const [value, setValue] = useState(() => {
		if (!storage[key]) storage[key] = defaultValues;
		return storage[key];
	});

	useEffect(() => {
		const onSet = (value) => {
			storage[key] = value;
			setValue(value);
		};
		events.on("set:" + key, onSet);
		return () => events.off("set:" + key, onSet);
	}, [key]);

	const set = useCallback(
		(newValue) =>
			setItem(
				key,
				typeof storage[key] === "object"
					? { ...storage[key], ...newValue }
					: newValue
			),
		[key, value]
	);

	return [value, set];
};

export const setItem = async (key, value) => {
	events[key] = value;
	events.emit("set:" + key, value);
	await AsyncStorage.setItem(key, JSON.stringify(value));
};

export const clear = async () => {
	await AsyncStorage.clear();
};

export default { init, useStorage, setItem, clear };
