import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={64}
    height={64}
    viewBox="0 0 511 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#a)" fill="#fff">
      <Path d="M51.385 93.11h99.905c0 6.33.628 12.637 1.908 18.758a92.16 92.16 0 0 0 5.423 17.5 95.892 95.892 0 0 0 8.564 15.801c3.608 5.329 10.821 6.725 16.15 3.142 5.329-3.608 6.725-10.821 3.142-16.15a71.057 71.057 0 0 1-6.446-11.846c-1.768-4.259-3.142-8.657-4.049-13.125a70.992 70.992 0 0 1-1.419-14.08c0-4.747.488-9.495 1.419-14.08a69.722 69.722 0 0 1 4.049-13.078 69.39 69.39 0 0 1 6.423-11.868 70.107 70.107 0 0 1 8.517-10.333 72.935 72.935 0 0 1 10.38-8.54 71.017 71.017 0 0 1 11.846-6.446 68.759 68.759 0 0 1 13.102-4.05c9.193-1.884 18.967-1.884 28.158 0a70.296 70.296 0 0 1 13.079 4.05 69.416 69.416 0 0 1 11.868 6.423 70.127 70.127 0 0 1 10.333 8.517 71.399 71.399 0 0 1 8.54 10.356 71.053 71.053 0 0 1 6.446 11.845 68.746 68.746 0 0 1 4.049 13.103 71.393 71.393 0 0 1 1.419 14.102c0 4.748-.488 9.495-1.419 14.08a70.232 70.232 0 0 1-4.049 13.078 67.936 67.936 0 0 1-6.446 11.869c-3.583 5.329-2.187 12.567 3.142 16.15a11.554 11.554 0 0 0 6.492 1.978c3.748 0 7.401-1.792 9.658-5.12a91.876 91.876 0 0 0 8.587-15.825 91.835 91.835 0 0 0 5.423-17.453 93.474 93.474 0 0 0 1.885-18.758c0-6.33-.629-12.636-1.908-18.757a92.16 92.16 0 0 0-5.423-17.5 93.673 93.673 0 0 0-8.587-15.826 95.727 95.727 0 0 0-11.357-13.776 96.117 96.117 0 0 0-13.754-11.357 91.857 91.857 0 0 0-15.825-8.587 91.852 91.852 0 0 0-17.453-5.423c-12.241-2.513-25.273-2.513-37.514 0a92.172 92.172 0 0 0-17.501 5.423c-5.538 2.396-10.868 5.283-15.825 8.634a95.758 95.758 0 0 0-13.776 11.357 96.09 96.09 0 0 0-11.357 13.753 96.926 96.926 0 0 0-8.587 15.825c-1.769 4.19-2.886 8.587-4.003 12.963H51.385l26.67-26.67c4.537-4.537 4.537-11.915 0-16.453-4.539-4.538-11.916-4.538-16.454 0l-46.52 46.52a12.048 12.048 0 0 0-2.536 3.793 11.542 11.542 0 0 0 0 8.89 11.087 11.087 0 0 0 2.536 3.792l46.52 46.52a11.612 11.612 0 0 0 8.238 3.421c2.98 0 5.957-1.14 8.238-3.398a11.673 11.673 0 0 0 0-16.477L51.385 93.11Z" />
      <Path d="M465.504 256.779c-12.217-3.072-24.946-1-34.954 4.771v-6.237c0-25.552-20.875-46.31-46.544-46.31-8.47 0-16.429 2.257-23.271 6.214v-6.214c0-25.669-20.875-46.544-46.544-46.544a46.162 46.162 0 0 0-23.271 6.261V92.877c0-25.53-20.875-46.31-46.544-46.31-25.669 0-46.543 20.782-46.543 46.31v144.936c-6.563-3.678-14.173-5.772-22.341-5.772-14.544 0-26.716 5.213-35.234 15.057-10.17 11.776-14.312 29.648-11.426 49.057 12.404 81.312 41.981 149.823 87.874 203.627 6.632 7.773 16.406 12.218 26.809 12.218h182.217c14.102 0 26.739-8.238 32.137-21.037 26.368-62.601 41.843-130.368 42.471-185.848.28-23.225-14.055-43.1-34.836-48.336Zm-29.09 225.131c-1.745 4.142-5.934 6.819-10.704 6.819H243.493c-3.538 0-6.912-1.489-9.076-4.027-43.076-50.476-70.862-115.078-82.591-192.014-1.862-12.474.396-23.83 6.027-30.392 4.049-4.701 9.821-6.982 17.593-6.982 12.52 0 22.341 10.216 22.341 23.272 0 6.423 5.213 11.636 11.636 11.636 6.423 0 11.636-5.213 11.636-11.636V92.876c.047-12.473 10.705-23.038 23.318-23.038 12.614 0 23.272 10.565 23.272 23.039v116.125c0 6.423 5.213 11.636 11.636 11.636 6.423 0 11.636-5.213 11.636-11.636 0-12.823 10.449-23.271 23.271-23.271 12.823 0 23.272 10.449 23.272 23.271v46.31c0 6.423 5.213 11.636 11.636 11.636 6.423 0 11.636-5.213 11.636-11.636 0-12.497 10.659-23.039 23.271-23.039 12.613 0 23.272 10.565 23.272 23.039v46.31c0 6.423 5.213 11.637 11.636 11.637 6.423 0 11.636-5.214 11.636-11.637 0-14.451 14.708-25.831 29.253-22.294 10.146 2.561 17.406 13.288 17.267 25.506-.583 51.828-15.78 118.035-40.657 177.076Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h512v512H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
