import { useCallback, useEffect } from "react";
import {
  ScrollView,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";
import * as Updates from "expo-updates";

const reload = () =>
  Platform.OS === "web" ? location.reload() : Updates.reloadAsync();

export default ({ error = "no info" }) => {
  return (
    <ScrollView style={styles.scroll} contentContainerStyle={styles.container}>
      <Text style={styles.smile}>🙊</Text>
      <Text style={styles.title}>Opps</Text>
      <Text style={styles.subtitle}>Unhandled Error</Text>
      <Text style={styles.message}>{error.toString()}</Text>
      <View style={styles.buttons}>
        <TouchableOpacity style={styles.button} onPress={reload} primary>
          <Text style={styles.buttonText} primary>
            Restart
          </Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
    backgroundColor: "#2e2e2e",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  smile: {
    color: "white",
    fontSize: 75,
    marginBottom: 8,
  },
  title: {
    color: "white",
    fontSize: 30,
    marginBottom: 8,
  },
  subtitle: {
    color: "white",
    fontSize: 17,
    marginBottom: 16,
  },
  message: {
    color: "white",
    fontSize: 14,
    marginBottom: 16,
    textAlign: "center",
    paddingHorizontal: 16,
    maxWidth: 500,
  },
  buttons: {
    paddingVertical: 16,
    alignItems: "center",
  },
  button: {
    backgroundColor: "#26a4df",
    height: 44,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16,
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  buttonText: {
    color: "white",
    fontSize: 17,
    fontWeight: "500",
  },
});
