import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={64}
    height={64}
    viewBox="0 0 511 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#a)" fill="#fff">
      <Path d="M437.079 93.111h-99.905c0 6.33-.629 12.637-1.908 18.758a92.16 92.16 0 0 1-5.423 17.5 95.851 95.851 0 0 1-8.564 15.801c-3.608 5.329-10.822 6.726-16.15 3.142-5.329-3.607-6.726-10.821-3.142-16.15a71.13 71.13 0 0 0 6.446-11.846c1.768-4.258 3.141-8.657 4.049-13.125a70.99 70.99 0 0 0 1.419-14.08 70.99 70.99 0 0 0-1.419-14.08 69.796 69.796 0 0 0-4.049-13.078 69.46 69.46 0 0 0-6.423-11.868 70.114 70.114 0 0 0-8.518-10.333 72.87 72.87 0 0 0-10.379-8.54 71.056 71.056 0 0 0-11.846-6.446 68.78 68.78 0 0 0-13.102-4.05c-9.193-1.884-18.967-1.884-28.158 0a70.272 70.272 0 0 0-13.079 4.05 69.379 69.379 0 0 0-11.868 6.423 70.127 70.127 0 0 0-10.333 8.517 71.399 71.399 0 0 0-8.54 10.356 70.98 70.98 0 0 0-6.446 11.846 68.746 68.746 0 0 0-4.049 13.102 71.394 71.394 0 0 0-1.42 14.102c0 4.748.489 9.495 1.42 14.08a70.232 70.232 0 0 0 4.049 13.078 67.87 67.87 0 0 0 6.446 11.869c3.583 5.329 2.187 12.567-3.142 16.15a11.551 11.551 0 0 1-6.493 1.978c-3.747 0-7.4-1.792-9.657-5.12a91.77 91.77 0 0 1-8.587-15.825 91.835 91.835 0 0 1-5.423-17.453A93.4 93.4 0 0 1 151 93.111c0-6.33.628-12.636 1.908-18.757a92.16 92.16 0 0 1 5.423-17.5 93.598 93.598 0 0 1 8.587-15.826 95.727 95.727 0 0 1 11.357-13.776 96.108 96.108 0 0 1 13.753-11.357 91.894 91.894 0 0 1 15.825-8.587 91.862 91.862 0 0 1 17.454-5.423c12.241-2.513 25.273-2.513 37.514 0a92.163 92.163 0 0 1 17.5 5.423c5.539 2.396 10.869 5.283 15.825 8.634a95.766 95.766 0 0 1 13.777 11.357 96.09 96.09 0 0 1 11.357 13.753 97.002 97.002 0 0 1 8.587 15.825c1.768 4.19 2.885 8.588 4.002 12.963h103.21l-26.67-26.67c-4.537-4.537-4.537-11.915 0-16.453 4.538-4.538 11.916-4.538 16.454 0l46.52 46.52a12.06 12.06 0 0 1 2.536 3.793 11.54 11.54 0 0 1 0 8.89 11.095 11.095 0 0 1-2.536 3.792l-46.52 46.52a11.612 11.612 0 0 1-8.238 3.421c-2.979 0-5.957-1.14-8.238-3.398a11.673 11.673 0 0 1 0-16.477l26.692-26.667Z" />
      <Path d="M465.504 256.779c-12.217-3.072-24.947-1-34.954 4.771v-6.237c0-25.552-20.875-46.31-46.544-46.31-8.47 0-16.429 2.257-23.271 6.214v-6.214c0-25.669-20.875-46.544-46.544-46.544a46.162 46.162 0 0 0-23.271 6.261V92.877c0-25.53-20.875-46.31-46.544-46.31-25.669 0-46.543 20.782-46.543 46.31v144.936c-6.563-3.678-14.173-5.772-22.341-5.772-14.545 0-26.716 5.213-35.234 15.057-10.17 11.776-14.312 29.648-11.426 49.057 12.404 81.312 41.981 149.823 87.874 203.627 6.632 7.773 16.406 12.218 26.809 12.218h182.217c14.102 0 26.739-8.238 32.137-21.037 26.368-62.601 41.842-130.368 42.471-185.848.28-23.225-14.055-43.1-34.836-48.336Zm-29.09 225.131c-1.745 4.142-5.934 6.819-10.704 6.819H243.493c-3.538 0-6.912-1.489-9.076-4.027-43.076-50.476-70.862-115.078-82.591-192.014-1.862-12.474.396-23.83 6.027-30.392 4.049-4.701 9.821-6.982 17.593-6.982 12.52 0 22.341 10.216 22.341 23.272 0 6.423 5.213 11.636 11.636 11.636 6.423 0 11.636-5.213 11.636-11.636V92.876c.047-12.473 10.705-23.038 23.318-23.038 12.614 0 23.272 10.565 23.272 23.039v116.125c0 6.423 5.213 11.636 11.636 11.636 6.423 0 11.636-5.213 11.636-11.636 0-12.823 10.449-23.271 23.271-23.271 12.823 0 23.272 10.449 23.272 23.271v46.31c0 6.423 5.213 11.636 11.636 11.636 6.423 0 11.636-5.213 11.636-11.636 0-12.497 10.659-23.039 23.271-23.039 12.613 0 23.272 10.565 23.272 23.039v46.31c0 6.423 5.213 11.637 11.636 11.637 6.423 0 11.636-5.214 11.636-11.637 0-14.451 14.708-25.831 29.253-22.294 10.146 2.561 17.406 13.288 17.267 25.506-.583 51.828-15.78 118.035-40.657 177.076Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h512v512H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
