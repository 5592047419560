import {
	StyleSheet,
	TouchableWithoutFeedback,
	Animated,
	SafeAreaView,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useAnimation } from "react-native-animation-hooks";

import BestScore from "./BestScore";
import Footer from "./Footer";

const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);

export default function GameOver({ game }) {
	const opacity = useAnimation({
		type: "timing",
		duration: 300,
		initialValue: 0,
		toValue: 1,
		useNativeDriver: true,
	});

	return (
		<TouchableWithoutFeedback onPress={game.reset}>
			<AnimatedLinearGradient
				style={[styles.container, { opacity }]}
				colors={["transparent", "#00000040"]}
			>
				<SafeAreaView style={styles.safe}>
					<BestScore game={game} />
					<Footer game={game} />
				</SafeAreaView>
			</AnimatedLinearGradient>
		</TouchableWithoutFeedback>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	safe: {
		flex: 1,
		justifyContent: "flex-end",
		paddingTop: 100,
	},
});
