import { Platform } from "react-native";
import bridge from "@vkontakte/vk-bridge";
import l from "@lib/lang";
import axios from "axios";

import Sharing from "expo-sharing";
import Api from "./Api";

function addToFavorites() {
	if (window.sidebar && window.sidebar.addPanel) {
		// Mozilla Firefox Bookmark
		window.sidebar.addPanel(document.title, window.location.href, "");
	} else if (window.external && "AddFavorite" in window.external) {
		// IE Favorite
		window.external.AddFavorite(location.href, document.title);
	} else if (window.opera && window.print) {
		// Opera Hotlist
		this.title = document.title;
		return true;
	} else {
		// webkit - safari/chrome
		alert(
			l("addToBookmark", {
				key:
					(navigator.userAgent.toLowerCase().indexOf("mac") != -1
						? "Command/Cmd"
						: "CTRL") + " + D",
			})
		);
	}
	return false;
}

class ApiWeb extends Api {
	async init() {
		await super.init();
		if (Platform.vk) {
			bridge.send("VKWebAppInit", {});
			bridge.subscribe((e) => console.log("vk bridge event", e));
		}
	}

	share({ bestScore }) {
		if (Platform.vk)
			bridge.send("VKWebAppShowWallPostBox", {
				message: l("shareText", { count: bestScore }),
				attachments: "https://vk.com/app8048879",
			});
		else super.share({ bestScore });
	}

	addToFavorites() {
		if (Platform.vk) bridge.send("VKWebAppAddToFavorites");
		if (Platform.ya)
			ysdk.shortcut
				.showPrompt()
				.then((result) => console.log({ result }));
		else addToFavorites();
	}

	showLeaderboard({ score }) {
		if (Platform.vk)
			bridge.send("VKWebAppShowLeaderBoardBox", { user_result: score });
		if (Platform.ya)
			ysdk.getLeaderboards()
				.then((lb) => {
					lb.getLeaderboardEntries("bestscores", {
						includeUser: true,
					}).then((res) => console.log(res));
				})
				.then((res) => console.log(res));
		else super.showLeaderboard();
	}

	invite() {
		if (Platform.vk) bridge.send("VKWebAppShowInviteBox");
		else super.invite();
	}

	async submitBestScore({ score }) {
		if (Platform.vk) {
			axios.post(
				"https://monkeyjump-api.epicstar.io/vkAddAppEvent" +
					location.search,
				{ score }
			);
		}
		if (Platform.ya) {
			ysdk.getLeaderboards().then((lb) => {
				lb.setLeaderboardScore("bestscores", score);
			});
		}
	}
}

export default new ApiWeb();
