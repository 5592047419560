import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={41}
    height={41}
    viewBox="0 0 163 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M120.748 0H41.806C18.754 0 0 18.755 0 41.807v78.942c0 23.052 18.754 41.806 41.806 41.806h78.942c23.052 0 41.807-18.754 41.807-41.806V41.807C162.555 18.755 143.8 0 120.748 0Zm26.807 120.748c0 14.781-12.025 26.806-26.807 26.806H41.806C27.025 147.555 15 135.529 15 120.748V41.806C15 27.025 27.025 15 41.806 15h78.942c14.781 0 26.807 12.025 26.807 26.806v78.942Z"
      fill="#fff"
    />
    <Path
      d="m84.999 86.466-21.122-55.97a8.754 8.754 0 0 0-8.206-5.649h-.008a8.755 8.755 0 0 0-8.192 5.63L26.155 86.446a7.5 7.5 0 1 0 14.017 5.339l3.469-9.108h23.895l3.429 9.086a7.504 7.504 0 0 0 7.019 4.854 7.5 7.5 0 0 0 7.015-10.15Zm-35.645-18.79 6.289-16.513 6.232 16.513H49.354ZM108.131 105.043l7.929-7.929a7.5 7.5 0 0 0 0-10.606 7.5 7.5 0 0 0-10.606 0L84.722 107.24a7.5 7.5 0 0 0 0 10.606l20.732 20.732a7.478 7.478 0 0 0 5.303 2.197 7.474 7.474 0 0 0 5.303-2.197 7.5 7.5 0 0 0 0-10.606l-7.929-7.929h26.37a7.5 7.5 0 0 0 0-15h-26.37Z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
