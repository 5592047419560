import { EventEmitter } from "events";
import { Asset } from "expo-asset";
import { Platform as P } from "react-native";
import AssetUtils from "expo-asset-utils";
import { PIXI } from "expo-pixi";

import SpriteSheetImage from "@assets/spritesheet.png";
import Settings from "./Settings";
import SpriteSheet from "./SpriteSheet";
import BrokenPlatform from "./BrokenPlatform";
import CrashedPlatform from "./CrashedPlatform";
import Platform from "./Platform";
import Player from "./Player";
import Spring from "./Spring";
import * as Sound from "@lib/sound";

let broken = 0;
const levels = [50, 100, 150, 200, 250, 300];

async function setupSpriteSheet(resource, spriteSheet) {
  const texture = await PIXI.Texture.from(resource);

  let textures = {};
  for (const sprite of spriteSheet) {
    const { name, x, y, width, height } = sprite;
    textures[name] = new global.PIXI.Texture(
      texture.baseTexture,
      new PIXI.Rectangle(x, y, width, height)
    );
  }

  return textures;
}

class MonkeyJump extends EventEmitter {
  state;
  position = 0;
  score = 0;
  jumpCount = 0;
  platforms = [];

  get width() {
    return this.app.renderer.width;
  }

  get height() {
    return this.app.renderer.height;
  }

  get maxWidth() {
    const max = P.select({ web: 400 * Settings.scale, default: 1000 });
    return this.width < max ? this.width : max;
  }

  constructor(settings) {
    super();
    this.settings = settings;
  }

  onContextCreate = (gl) => {
    this.app = new PIXI.Application({
      context: gl,
      transparent: true,
      powerPreference: "high-performance",
    });
    !this.gl && this.init();
    this.gl = gl;
  };

  init() {
    this.platformInterval = this.app.renderer.height / Settings.platformCount;
    this.setup();
    this.setState("running");
    this.emit("scoreChange", this.score);
  }

  setSettings(props) {
    this.settings = { ...this.settings, ...props };
    this.emit("settingsChange", this.settings);
  }

  setup = async () => {
    await Asset.fromModule(SpriteSheetImage).downloadAsync();
    this.textures = await setupSpriteSheet(SpriteSheetImage, SpriteSheet);
    this.setupGame();
    this.app.ticker.speed = 1;
    this.app.ticker.add(this.update);
  };

  getLevel = (score) => {
    for (const index in levels) {
      if (score < levels[index]) {
        return parseInt(index);
      }
    }
    return levels.length - 1;
  };

  getPlatformForLevel = (score, first, offset) => {
    const level = this.getLevel(score);
    const types = Platform.Levels[level];
    let type = types[Math.floor(Math.random() * types.length)];
    if (type === Platform.Types.breakable) {
      if (broken < 1) {
        broken += 1;
      } else if (broken >= 1) {
        type = Platform.Types.normal;
        broken = 0;
      }
    }

    const { textures } = this;

    const d = (this.width - this.maxWidth) / 2;

    const platform = new Platform({ type, textures });

    if (typeof offset === "number")
      platform.x =
        this.width / 2 - platform.width / 2 + platform.width * offset;
    else if (first) platform.x = this.width / 2 - platform.width / 2;
    else platform.x = d + (this.maxWidth - platform.width) * Math.random();
    return platform;
  };

  setupGame = async () => {
    this.setupPlayer();
    this.setupSpring();
    this.setupBrokenPlatform();
    this.setupCrashedPlatform();
    this.setupPlatforms();
    await this.setupSounds();
  };

  async setupSounds() {
    await Sound.setupSounds([
      ["jump", require(`@assets/sounds/jump.mp3`)],
      ["jumpHigh", require(`@assets/sounds/jumpHigh.mp3`)],
      ["wood", require(`@assets/sounds/wood.mp3`)],
      ["woodBreak", require(`@assets/sounds/woodBreak.mp3`)],
      ["cloud", require(`@assets/sounds/cloud.mp3`)],
      ["ice", require(`@assets/sounds/ice.mp3`)],
      ["gameOver", require(`@assets/sounds/gameOver.mp3`)],
    ]);
    await Sound.setupMusic([
      require(`@assets/music/Nintendo_Revolution.mp3`),
      require(`@assets/music/Binary_Heavens.mp3`),
      require(`@assets/music/Helios.mp3`),
    ]);
    this.settings.soundEnabled && Sound.playNextMusic();
  }

  setSoundEnabled(enabled) {
    if (enabled) Sound.resumeMusic();
    else Sound.pauseMusic();
  }

  setupPlayer = () => {
    this.player = new Player({
      game: this,
      app: this.app,
      sounds: this.sounds,
      texture: this.textures.player,
    });
    this.app.stage.addChild(this.player);
  };

  setupSpring = () => {
    this.spring = new Spring(
      this.textures.spring_closed,
      this.textures.spring_open
    );
    this.app.stage.addChild(this.spring);
  };

  setupBrokenPlatform = () => {
    const { texture } = Platform.Styles[Platform.Types.falling];
    this.brokenPlatform = new BrokenPlatform(this.textures[texture]);
    this.app.stage.addChild(this.brokenPlatform);
  };

  setupCrashedPlatform = () => {
    const { texture } = Platform.Styles[Platform.Types.crashed];
    this.crashedPlatform = new CrashedPlatform(this.textures[texture]);
    this.app.stage.addChild(this.crashedPlatform);
  };

  setupPlatforms = () => {
    for (let i = 0; i < Settings.platformCount; i++) {
      const platform = this.getPlatformForLevel(
        this.score,
        i === Settings.platformCount - 3,
        i === Settings.platformCount - 4
          ? Math.random() > 0.5
            ? -1
            : 1
          : i === Settings.platformCount - 2
          ? Math.random() > 0.5
            ? -1
            : 1
          : i === Settings.platformCount - 1
          ? Math.random() > 0.5
            ? -2
            : 2
          : false
      );
      platform.y = this.position;
      this.position += this.platformInterval;
      this.platforms.push(platform);
      this.app.stage.addChild(platform);
    }
  };

  update = (delta) => {
    this.updatePlatforms(delta);
    this.updateSprings(delta);
    this.updatePlayer(delta);
    this.updateCollisions(delta);
  };

  direction = 0;
  directionVelocity = 0;

  setControlsDirection = (d) => {
    this.direction = d;
  };

  updateControls = (x) => {
    if (this.settings.controls !== "rotate") return;
    if (this.player) {
      this.player.velocity.x = x * 50 * (Settings.scale / 2);
    }
  };

  state = "running";

  setState(state) {
    this.state !== state && this.emit("stateChange", state);
    this.state = state;

    if (state === "game_over") this.setControlsDirection(0);
  }

  updatePlayer = (d) => {
    const { player, height } = this;

    if (player.y - player.height > height) {
      this.setState("game_over");
    }

    if (this.settings.controls === "touch") {
      const dirVel = 0.7;

      if (this.direction) {
        this.directionVelocity +=
          d * this.direction * dirVel * (Settings.scale / 3);
        const MAX_VELOCITY = 15;
        if (this.directionVelocity < -MAX_VELOCITY)
          this.directionVelocity = -MAX_VELOCITY;
        if (this.directionVelocity > MAX_VELOCITY)
          this.directionVelocity = MAX_VELOCITY;
      } else {
        if (this.directionVelocity > 0)
          this.directionVelocity -= d * dirVel * (Settings.scale / 3);
        else if (this.directionVelocity < 0)
          this.directionVelocity += d * dirVel;
        if (this.directionVelocity > -2 && this.directionVelocity < 2)
          this.directionVelocity = 0;
      }
      this.player.velocity.x = this.directionVelocity * 1.3;
    }

    //Movement of player affected by gravity
    const middle = height / 2 - player.height / 2;
    if (player.y < middle) {
      const change = (player.y - middle) * 0.1;
      let delta = player.velocity.y < 0 ? player.velocity.y : 0;

      this.brokenPlatform.y -= change;

      // When the player reaches half height move everything to make it look like a camera is moving up
      for (let index in this.platforms) {
        const platform = this.platforms[index];
        platform.y -= delta;
        platform.y -= change;

        if (platform.y > height) {
          const nextPlatform = this.getPlatformForLevel(this.score);
          nextPlatform.y = platform.y - height;
          this.app.stage.addChild(nextPlatform);
          this.platforms[index] = nextPlatform;

          this.score++;
          this.emit("scoreChange", this.score);
        }
      }

      player.y -= change;
    }

    this.player.update(d);
  };

  updateCollisions = (dt) => {
    if (this.player.velocity.y <= 0) {
      return;
    }
    this.checkPlatformCollision(dt);
    this.checkSpringCollision(dt);
  };

  updateSprings = () => {
    const { spring } = this;
    const platform = this.platforms[0];

    if (platform.canHaveSpring) {
      spring.visible = true;
      spring.x = platform.x + platform.width / 2 - spring.width / 2;
      spring.y = platform.y - spring.height;

      if (spring.y > this.height / 1.1) {
        spring.interacted = false;
      }
    } else {
      spring.visible = false;
    }
  };

  updatePlatforms = (dt) => {
    const { brokenPlatform, crashedPlatform, platforms } = this;

    for (let platform of platforms) {
      platform.update(dt);
      if (platform.type === Platform.Types.moving) {
        const d = (this.width - this.maxWidth) / 2;
        if (platform.left < d) {
          platform.velocity.x = 1;
        }
        if (platform.right > d + this.maxWidth) {
          platform.velocity.x = -1;
        }
      }

      if (
        platform.type === Platform.Types.breakable &&
        platform.interacted &&
        !brokenPlatform.visible &&
        platform.jumpCount === 2
      ) {
        // console.log("updateBrokenPlatform");
        brokenPlatform.x = platform.x;
        brokenPlatform.y = platform.y;
        brokenPlatform.visible = true;
        platform.visible = false;
        platform.interacted = false;
      }

      if (
        platform.type === Platform.Types.vanishable &&
        platform.interacted &&
        !brokenPlatform.visible &&
        platform.jumpCount === 1
      ) {
        // console.log("updateCrashedPlatform");
        crashedPlatform.x = platform.x;
        crashedPlatform.y = platform.y;
        crashedPlatform.visible = true;
        platform.visible = false;
        platform.interacted = false;
      }
    }

    brokenPlatform.update(dt);
    crashedPlatform.update(dt);

    if (brokenPlatform.y > this.height) {
      brokenPlatform.visible = false;
    }

    if (crashedPlatform.y > this.height) {
      crashedPlatform.visible = false;
    }
  };

  checkPlatformCollision = (dt) => {
    const { player, platforms } = this;

    for (let platform of platforms) {
      if (
        !platform.interacted &&
        player.left + Settings.collisionBuffer < platform.right &&
        player.right - Settings.collisionBuffer > platform.left &&
        player.bottom > platform.top &&
        player.bottom < platform.bottom
      ) {
        if (platform.type === Platform.Types.breakable) {
          // console.log("collision: breakable");
          platform.jumpCount++;
          if (platform.jumpCount > 1) platform.interacted = true;

          if (platform.jumpCount > 2) return;
        } else if (platform.type === Platform.Types.vanishable) {
          // console.log("collision: vanishable");
          platform.interacted = true;
          platform.visible = false;
          platform.jumpCount++;
          if (platform.jumpCount > 1) {
            platform.interacted = true;
            return;
          }
        }
        player.jump(dt, platform);
        return;
      }
    }
  };

  checkSpringCollision = (dt) => {
    const { player, spring } = this;

    if (
      !spring.interacted &&
      player.left + Settings.collisionBuffer < spring.right &&
      player.right - Settings.collisionBuffer > spring.left &&
      player.bottom > spring.top &&
      player.bottom < spring.bottom
    ) {
      spring.interacted = true;
      player.jumpHigh(dt);
    }
  };

  reset = () => {
    this.position = 0;
    this.score = 0;
    this.player.reset();
    this.resetPlatforms();
    this.setupPlatforms();
    this.setState("running");
    this.emit("scoreChange", 0);
  };

  resetPlatforms = () => {
    for (const platform of this.platforms) {
      platform.reset();
      this.app.stage.removeChild(platform);
    }
    this.platforms = [];
  };
}

export default MonkeyJump;
