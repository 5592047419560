import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 525.153 525.153"
    style={{
      enableBackground: "new 0 0 525.153 525.153",
    }}
    xmlSpace="preserve"
    width={36}
    height={36}
    {...props}
  >
    <Path
      d="M196.932 525.153H328.22V0H196.932v525.153zM0 525.153h131.288V262.576H0v262.577zM393.864 164.11v361.042h131.288V164.11H393.864z"
      fill="#FFF"
    />
  </Svg>
);

export default SvgComponent;
