import { memo, useState, useRef, useCallback, useEffect } from "react";
import {
	StyleSheet,
	Platform,
	Animated,
	TouchableWithoutFeedback,
	Dimensions,
	useWindowDimensions,
	View,
} from "react-native";
import { GLView } from "expo-gl";
import { useAnimation } from "react-native-animation-hooks";

import Game from "@game";
import Accelerometer from "@lib/controls/accelerometer";
import KeysControl from "@lib/controls/keys";
import TouchControl from "./TouchControl";
import Tutorial from "./Tutorial";

const { width, height } = Dimensions.get("window");

export default memo(
	function ({ game, state }) {
		const window = useWindowDimensions();
		const opacity = useAnimation({
			type: "timing",
			duration: 300,
			initialValue: 0,
			toValue: state === "game_over" ? 0 : 1,
			useNativeDriver: true,
		});

		useEffect(() => {
			const unsubscribe = Accelerometer.subscribe(({ x }) =>
				game.updateControls(x)
			);

			return () => unsubscribe();
		}, []);

		useEffect(() => {
			const unsubscribe = KeysControl.subscribe({
				onDirection: game.setControlsDirection,
				onReset: () => game.state === "game_over" && game.reset(),
			});

			return () => unsubscribe();
		}, []);

		useEffect(() => {
			if (Platform.OS !== "web") return;
			const visibilitychange = (e) => {
				if (!game.settings.soundEnabled) return;
				game.setSoundEnabled(!document.hidden);
			};
			document.addEventListener("visibilitychange", visibilitychange);
			return () => {
				document.removeEventListener(
					"visibilitychange",
					visibilitychange
				);
			};
		}, []);

		const style =
			window.width < width
				? { width: window.width }
				: { height: window.height };

		const ratio = width / height;

		const scale =
			window.width < width
				? window.width / width
				: window.height / height;

		return (
			<TouchControl onDirection={game.setControlsDirection}>
				<View style={styles.wrap}>
					<Animated.View
						style={[styles.container, { opacity, ...style }]}
					>
						<GLView
							style={[styles.gl, { transform: [{ scale }] }]}
							onContextCreate={game.onContextCreate}
						/>
						<Tutorial game={game} />
					</Animated.View>
				</View>
			</TouchControl>
		);
	},
	(a, b) => a.state === b.state
);

const style =
	width > height
		? { aspectRatio: width / height }
		: { aspectRatio: height / width };

const styles = StyleSheet.create({
	wrap: {
		position: "absolute",
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		position: "absolute",
		aspectRatio: width / height,
		width,
		alignItems: "center",
		justifyContent: "center",
	},
	gl: {
		width: width,
		height: height,
		position: "absolute",
	},
});
