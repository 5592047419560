const keysPressed = new Set();

const subscribe = ({ onDirection, onReset }) => {
	const onKeyDown = (e) => {
		switch (e.keyCode) {
			case 37:
			case 65:
				onDirection(-1);
				keysPressed.add(e.keyCode);
				break;
			case 39:
			case 68:
				onDirection(1);
				keysPressed.add(e.keyCode);
				break;
			case 13:
			case 32:
			case 38:
			case 40:
			case 83:
			case 87:
				onReset();
				break;
		}
	};

	const onKeyUp = (e) => {
		keysPressed.delete(e.keyCode);
		if (!keysPressed.size) onDirection(0);
		else {
			const key = [...keysPressed].pop();
			switch (key) {
				case 65:
					onDirection(-1);
					break;
				case 68:
					onDirection(1);
					break;
			}
		}
	};

	document.addEventListener("keydown", onKeyDown);
	document.addEventListener("keyup", onKeyUp);
	return () => {
		document.removeEventListener("keydown", onKeyDown);
		document.removeEventListener("keyup", onKeyUp);
	};
};

export default { subscribe };
