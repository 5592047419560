import * as SystemUI from "expo-system-ui";
import { memo, useCallback, useEffect, useState, useRef } from "react";
import { Platform, StyleSheet, Animated, Easing } from "react-native";
import { LinearGradient, Bounce, CurvedTransition } from "expo-linear-gradient";
import { Dimensions } from "react-native";
import chroma from "chroma-js";

const colors = chroma
	.scale(["#ECD078", "#D95B43", "#C02942", "#542437", "#53777A", "#ECD078"])
	.colors(1000);

let index = 0;
const getColors = () => {
	let index2 = index + 170;
	if (index2 > colors.length) index2 -= colors.length;

	const color1 = colors[index2];
	const color2 = colors[index];
	// console.log(index, { color1, color2 });
	index++;
	if (index >= colors.length) index = 0;

	if (Platform.OS === "web") document.body.style.backgroundColor = color1;

	return [color1, color2];
};

function rand(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

const Particle = memo(() => {
	const { width, height } = Dimensions.get("window");
	const [style, setStyle] = useState();
	const anim = useRef(new Animated.Value(0)).current;

	const reset = useCallback(() => {
		setStyle({
			left: rand(0, width),
			top: rand(0, height / 5),
			opacity: anim.interpolate({
				inputRange: [0, 0.5, 1],
				outputRange: [0, 1, 0],
			}),
			transform: [
				{
					translateX: anim.interpolate({
						inputRange: [0, 1],
						outputRange: [0, rand(-100, 100)],
					}),
				},
				{
					translateY: anim.interpolate({
						inputRange: [0, 1],
						outputRange: [0, rand(-100, 100)],
					}),
				},
				{
					rotate: anim.interpolate({
						inputRange: [0, 1],
						outputRange: ["0deg", rand(-180, 180) + "deg"],
					}),
				},
				{
					scale: anim.interpolate({
						inputRange: [0, 0.5, 1],
						outputRange: [0, 1, 0],
					}),
				},
			],
		});
		anim.setValue(0);

		setTimeout(() => {
			Animated.timing(anim, {
				toValue: 1,
				duration: 10000,
				useNativeDriver: true,
				easing: Easing.linear,
			}).start(reset);
		}, Math.random() * 10000);
	}, []);

	useEffect(() => {
		reset();
	}, []);

	return <Animated.View style={[styles.particle, style]} />;
});

export default memo(
	function Background({ score }) {
		return (
			<LinearGradient style={styles.container} colors={getColors()}>
				<Particle />
				<Particle />
				<Particle />
				<Particle />
				<Particle />
			</LinearGradient>
		);
	},
	(a, b) => a.score === b.score
);

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		width: "100%",
		height: "100%",
		left: 0,
		top: 0,
	},
	particle: {
		position: "absolute",
		width: 10,
		height: 10,
		backgroundColor: "rgba(255, 255, 255, 0.4)",
		borderRadius: 3,
		shadowColor: "#fff",
		shadowOpacity: 1,
		shadowRadius: 5,
		elevation: 5,
		opacity: 0,
	},
});
