import { useEffect, useCallback, useState } from "react";
import {
	Platform,
	StyleSheet,
	View,
	Text,
	Animated,
	Image,
	TouchableOpacity,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useAnimation } from "react-native-animation-hooks";
import Constants from "expo-constants";

import { useStorage } from "@lib/storage";
import features from "@lib/features";
import l from "@lib/lang";

import SoundEnabled from "@icons/SoundEnabled.js";
import SoundDisabled from "@icons/SoundDisabled.js";
import Touch from "@icons/Touch.js";
import Rotate from "@icons/Rotate.js";
import Star from "@icons/Star.js";
import RemoveAds from "@icons/RemoveAds.js";
import Score from "@icons/Score.js";
import Invite from "@icons/Invite.js";

import Api from "@lib/api";

const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);

const Button = ({ Icon, onPress, setSoundEnabled }) => {
	return (
		<TouchableOpacity style={styles.button} onPress={onPress}>
			{Icon && <Icon />}
		</TouchableOpacity>
	);
};

export default ({ game }) => {
	return (
		<View style={styles.container}>
			<Animated.Text style={styles.text}>
				{l("restartMessage")}
			</Animated.Text>
			<Menu game={game} />
			<Text style={styles.version}>v{Constants.manifest.version}</Text>
		</View>
	);
};

function Menu({ game }) {
	const [settings, setSettings] = useStorage("settings");

	const setSoundEnabled = useCallback(() => {
		const soundEnabled = !settings.soundEnabled;
		setSettings({ soundEnabled });
		game.setSoundEnabled(soundEnabled);
	}, [settings.soundEnabled]);

	const setControls = useCallback(() => {
		setSettings({
			controls: settings.controls === "rotate" ? "touch" : "rotate",
		});
	}, [settings.controls]);

	return (
		<View style={styles.menu}>
			<Button
				Icon={settings.soundEnabled ? SoundEnabled : SoundDisabled}
				onPress={setSoundEnabled}
			/>
			{features.accelerometer && (
				<Button
					Icon={{ rotate: Rotate, touch: Touch }[settings.controls]}
					onPress={setControls}
				/>
			)}
			{features.favorites && (
				<Button Icon={Star} onPress={Api.addToFavorites} />
			)}
			{features.removeAds && (
				<Button Icon={RemoveAds} onPress={Api.removeAds} />
			)}
			{features.leaderboard && (
				<Button
					Icon={Score}
					onPress={() => Api.showLeaderboard({ score: game.score })}
				/>
			)}
			{features.invite && <Button Icon={Invite} onPress={Api.invite} />}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
	},
	text: {
		fontSize: 20,
		fontWeight: "200",
		color: "white",
		marginBottom: 30,
		textTransform: "uppercase",
		textAlign: "center",
	},
	menu: {
		justifyContent: "center",
		alignItems: "center",
		height: 50,
		flexDirection: "row",
		marginBottom: 32,
	},
	button: {
		marginHorizontal: 12,
	},
	version: {
		color: "white",
		fontSize: 11,
		opacity: 0.8,
		marginBottom: 16,
	},
});
