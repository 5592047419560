import { PixelRatio } from "react-native";
export default {
  platformCount: 10,
  gravity: 1,
  scale: PixelRatio.get(),
  jumpVelocity: 26,
  springVelocity: 45,
  platformFallingVelocity: 15,
  collisionBuffer: 15,
};
