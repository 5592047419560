import * as React from "react";
import Svg, { SvgProps, G, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508.528 508.528"
    style={{
      enableBackground: "new 0 0 508.528 508.528",
    }}
    xmlSpace="preserve"
    width={36}
    height={36}
    {...props}
  >
    <G fill="#FFF">
      <Path d="M263.54.116c-5.784-.54-12.554.858-20.531 5.689 0 0-132.533 115.625-138.317 121.314h-72.91C14.239 127.15 0 141.389 0 158.933v194.731c0 17.607 14.239 31.782 31.782 31.782h72.941c5.784 5.753 138.317 117.277 138.317 117.277 7.977 4.799 14.747 6.229 20.531 5.689 11.76-1.112 20.023-10.965 22.534-21.358.095-1.017.095-464.533-.064-465.55C283.563 11.081 275.268 1.228 263.54.116zM447.974 254.28l54.857-54.857c7.596-7.564 7.596-19.864 0-27.428-7.564-7.564-19.864-7.564-27.428 0l-54.857 54.888-54.888-54.888c-7.532-7.564-19.864-7.564-27.397 0-7.564 7.564-7.564 19.864 0 27.428l54.857 54.857-54.857 54.888c-7.564 7.532-7.564 19.864 0 27.396 7.532 7.564 19.864 7.564 27.396 0l54.888-54.857 54.857 54.857c7.564 7.564 19.864 7.564 27.428 0 7.564-7.532 7.564-19.864 0-27.396l-54.856-54.888z" />
    </G>
  </Svg>
);

export default SvgComponent;
