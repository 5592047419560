import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    viewBox="0 0 511 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path
      xmlns="http://www.w3.org/2000/svg"
      d="M141.55 0C76.407 0 26.423 58.844 37.595 123.555c1.414 8.187 9.199 13.64 17.332 12.23 8.164-1.41 13.64-9.168 12.23-17.332C59.168 72.164 94.875 30 141.551 30c46.535 0 82.308 41.965 74.43 88.27-1.391 8.168 4.105 15.914 12.269 17.304 8.168 1.395 15.914-4.105 17.305-12.273C256.52 58.85 206.773 0 141.55 0zm0 0"
      fill="#FFF"
    />
    <Path
      xmlns="http://www.w3.org/2000/svg"
      d="M301.172 240.633c-11.324-22.219-38.207-30.774-60-20-10.274-20.156-33.742-29.485-54.84-22.16V106.62c0-24.484-19.676-44.941-43.867-45.605-25.348-.72-46.352 19.582-46.352 44.984v231.082l-17.62-18.453c-16.915-17.707-45.235-18.953-63.138-2.77-18.89 17.075-19.832 46.149-2.437 64.422l101.273 108.02C128.316 503.359 148.258 512 168.906 512h122.145c41.355 0 75-33.645 75-75V281c0-33.332-35.16-55.063-64.88-40.367zm0 0"
      fill="#FFF"
    />
  </Svg>
);

export default SvgComponent;
