import { memo, useRef, useEffect, useCallback } from "react";
import { StyleSheet, View, Animated } from "react-native";
import { useAnimation } from "react-native-animation-hooks";

import ButtonsVertical from "@icons/ButtonsVertical.js";
import ButtonLeft from "@icons/ButtonLeft.js";
import ButtonRight from "@icons/ButtonRight.js";
import TouchLeft from "@icons/TouchLeft.js";
import TouchRight from "@icons/TouchRight.js";
import Rotate from "@icons/Rotate.js";

import { useStorage } from "@lib/storage";

function isTouchDevice() {
	return (
		"ontouchstart" in window ||
		navigator.maxTouchPoints > 0 ||
		navigator.msMaxTouchPoints > 0
	);
}

export default memo(
	({ game }) => {
		const [settings, setSettings] = useStorage("settings");
		const passed = settings.tutorialPassedFor.includes(settings.controls);

		const anim = useAnimation({
			type: "timing",
			duration: 300,
			initialValue: 0,
			toValue: passed ? 0 : 0.7,
			useNativeDriver: true,
		});

		const setPassed = useCallback(() => {
			setSettings({
				tutorialPassedFor: [
					...settings.tutorialPassedFor,
					settings.controls,
				],
			});
		}, [settings]);

		useEffect(() => {
			if (passed) return;

			const scoreChange = (score) => {
				if (score > 3) setPassed();
			};
			game.on("scoreChange", scoreChange);
			return () => {
				game.off("scoreChange", scoreChange);
			};
		}, [passed, settings.controls]);

		return (
			<Animated.View
				style={[styles.container, { opacity: anim }]}
				pointerEvents="none"
			>
				{settings.controls === "rotate" && <RotateControl />}
				{settings.controls === "touch" ? (
					isTouchDevice() ? (
						<TouchControl />
					) : (
						<ButtonsControl />
					)
				) : null}
			</Animated.View>
		);
	},
	() => true
);

const ButtonsControl = () => {
	const anim = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		Animated.loop(
			Animated.sequence([
				Animated.timing(anim, {
					toValue: 1,
					duration: 1000,
					useNativeDriver: true,
				}),
				Animated.timing(anim, {
					toValue: 0,
					duration: 1000,
					useNativeDriver: true,
				}),
			])
		).start();
	}, []);

	return (
		<View style={styles.buttons}>
			<Animated.View
				style={{
					transform: [
						{
							scale: anim.interpolate({
								inputRange: [0, 1],
								outputRange: [1, 0.9],
							}),
						},
					],
				}}
			>
				<ButtonLeft />
			</Animated.View>
			<ButtonsVertical style={styles.buttonsVertical} />
			<Animated.View
				style={{
					transform: [
						{
							scale: anim.interpolate({
								inputRange: [0, 1],
								outputRange: [0.9, 1],
							}),
						},
					],
				}}
			>
				<ButtonRight />
			</Animated.View>
		</View>
	);
};

const TouchControl = () => {
	const anim = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		Animated.loop(
			Animated.sequence([
				Animated.timing(anim, {
					toValue: 1,
					duration: 1000,
					useNativeDriver: true,
				}),
				Animated.timing(anim, {
					toValue: 0,
					duration: 1000,
					useNativeDriver: true,
				}),
			])
		).start();
	}, []);

	return (
		<View style={styles.touch}>
			<Animated.View
				style={[
					styles.touchSide,
					{
						transform: [
							{
								scale: anim.interpolate({
									inputRange: [0, 1],
									outputRange: [1, 0.8],
								}),
							},
						],
					},
				]}
			>
				<TouchLeft />
			</Animated.View>
			<Animated.View
				style={[
					styles.touchSide,
					{
						transform: [
							{
								scale: anim.interpolate({
									inputRange: [0, 1],
									outputRange: [0.8, 1],
								}),
							},
						],
					},
				]}
			>
				<TouchRight />
			</Animated.View>
		</View>
	);
};

const RotateControl = () => {
	const anim = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		Animated.loop(
			Animated.sequence([
				Animated.timing(anim, {
					toValue: 1,
					duration: 1000,
					useNativeDriver: true,
				}),
				Animated.timing(anim, {
					toValue: 0,
					duration: 1000,
					useNativeDriver: true,
				}),
			])
		).start();
	}, []);

	return (
		<Animated.View
			style={{
				transform: [
					{ scale: 1.5 },
					{
						rotate: anim.interpolate({
							inputRange: [0, 1],
							outputRange: ["-20deg", "20deg"],
						}),
					},
				],
			}}
		>
			<Rotate style={styles.rotate} />
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	container: {
		opacity: 0.9,
		position: "absolute",
		bottom: 0,
		left: 0,
		width: "100%",
		height: "25%",
		justifyContent: "center",
		alignItems: "center",
	},
	rotate: {
		transform: [{ rotate: "45deg" }],
	},
	touch: {
		width: "100%",
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
	},
	touchSide: {
		flex: 1,
		margin: 1,
		alignItems: "center",
		justifyContent: "center",
		maxWidth: 350,
	},
	buttons: {
		flexDirection: "row",
		alignItems: "flex-end",
	},
	buttonsVertical: {
		opacity: 0.5,
		marginHorizontal: 3,
	},
});
