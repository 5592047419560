import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    viewBox="0 0 48 48"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path
      d="M35.274 15.838H31.18a1.5 1.5 0 1 0 0 3h4.095c.827 0 1.5.673 1.5 1.5v19.616c0 .827-.673 1.5-1.5 1.5H12.726c-.827 0-1.5-.673-1.5-1.5V20.338c0-.827.673-1.5 1.5-1.5h4.095a1.5 1.5 0 1 0 0-3h-4.095a4.505 4.505 0 0 0-4.5 4.5v19.616c0 2.481 2.019 4.5 4.5 4.5h22.549c2.481 0 4.5-2.019 4.5-4.5V20.338a4.506 4.506 0 0 0-4.501-4.5zM18.407 12.81l4.143-4.143v19.748a1.5 1.5 0 1 0 3 0V8.667l4.143 4.143c.293.293.677.439 1.061.439s.768-.146 1.061-.439a1.5 1.5 0 0 0 0-2.121l-6.703-6.704a1.5 1.5 0 0 0-2.122 0l-6.704 6.704a1.5 1.5 0 1 0 2.121 2.121z"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    />
  </Svg>
);

export default SvgComponent;
