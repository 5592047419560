import { useRef, useState, useEffect } from "react";
import { Platform } from "react-native";
import * as StoreReview from "expo-store-review";

import SplashScreen from "@ui/SplashScreen";
import Background from "@ui/Background";
import GameView from "@ui/Game";
import Score from "@ui/Score";
import GameOver from "@ui/GameOver";

import Game from "@game";
import features from "@lib/features";
import useAppState from "@hooks/useAppState";
import * as Ads from "@lib/ads";
import { useStorage } from "@lib/storage";

export default function UI() {
	const [settings] = useStorage("settings");

	const gameRef = useRef(new Game(settings));
	const game = gameRef.current;
	const [state, setState] = useState(game.state);
	const [score, setScore] = useState(game.score);

	useAppState({
		onForeground: () => game.app?.ticker.start(),
		onBackground: () => game.app?.ticker.stop(),
	});

	useEffect(() => {
		game.setSettings(settings);
	}, [settings]);

	useEffect(() => {
		const scoreChange = (score) => setScore(score);
		const stateChange = (state) => setState(state);
		game.on("scoreChange", scoreChange);
		game.on("stateChange", stateChange);
		return () => {
			game.off("scoreChange", scoreChange);
			game.off("stateChange", stateChange);
		};
	}, []);

	const rate = useRef({
		gameOverCount: 0,
		rated: false,
	}).current;
	const ad = useRef({
		lastShow: 0,
	}).current;

	useEffect(() => {
		if (state !== "game_over") return;

		rate.gameOverCount++;

		if (
			features.storeReview &&
			game.score >= 20 &&
			rate.gameOverCount >= 5 &&
			!rate.rated
		) {
			if (Platform.ya)
				ysdk.feedback.requestReview().then(({ feedbackSent }) => {
					rate.rated = feedbackSent;
				});
			else StoreReview.requestReview().then(() => (rate.rated = true));
		} else {
			const diff = Date.now() - ad.lastShow;
			// if (diff > 15000) {
			Ads.show();
			ad.lastShow = Date.now();
			// }
		}
	}, [state]);

	return (
		<>
			<Background score={score} />
			<GameView game={game} state={state} />
			{state === "game_over" && <GameOver game={game} />}
			<Score score={score} />
		</>
	);
}
