import { Accelerometer as ExpoAccelerometer } from "expo-sensors";
import features from "@lib/features";

ExpoAccelerometer.setUpdateInterval(16);

const subscribe = (cb) => {
	let subscription;
	if (features.accelerometer)
		ExpoAccelerometer.addListener((e) => {
			e.x *= Platform.select({ android: -1, default: 1 });
			cb?.(e);
		});
	return () => subscription?.remove();
};

export default { subscribe };
