import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={41}
    height={41}
    viewBox="0 0 163 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M120.74 0H41.8C18.752 0 0 18.751 0 41.8v78.94c0 23.054 18.751 41.81 41.8 41.81h17.32a7.5 7.5 0 0 0 0-15H41.8c-14.777 0-26.8-12.027-26.8-26.81V41.8C15 27.022 27.022 15 41.8 15h78.94c14.783 0 26.81 12.022 26.81 26.8v78.94c0 14.783-12.027 26.81-26.81 26.81H89.12a7.5 7.5 0 0 0 0 15h31.62c23.054 0 41.81-18.756 41.81-41.81V41.8c0-23.049-18.756-41.8-41.81-41.8Z"
      fill="#fff"
    />
    <Path
      d="M50.114 25.682H34.512a7.497 7.497 0 0 0-7.5 7.514s.04 21.19.04 27.47c0 7.657.072 27.63.072 27.63a7.501 7.501 0 0 0 7.5 7.473h.029c.471-.002 11.6-.045 16.049-.123 16.896-.295 29.159-14.982 29.159-34.92-.001-20.96-11.955-35.044-29.747-35.044Zm.326 54.967c-1.928.034-5.242.061-8.341.082-.021-6.475-.046-15.48-.046-20.065 0-3.839-.015-13.242-.026-19.983h8.088c13.683 0 14.746 15.342 14.746 20.044-.001 13.588-7.244 19.797-14.421 19.922ZM99.714 127.967a7.5 7.5 0 0 0 0 10.606 7.474 7.474 0 0 0 5.303 2.197 7.474 7.474 0 0 0 5.303-2.197l20.732-20.732a7.497 7.497 0 0 0 0-10.606L110.32 86.503a7.5 7.5 0 0 0-10.606 0 7.5 7.5 0 0 0 0 10.606l7.929 7.929H81.272a7.5 7.5 0 0 0 0 15h26.37l-7.928 7.929Z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
