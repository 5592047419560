import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={46}
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    style={{ marginTop: -5 }}
    {...props}
  >
    <Path
      xmlns="http://www.w3.org/2000/svg"
      d="M41.5 18.49c0 3.109-1.26 6.02-3.56 8.2-.46.439-.96.84-1.48 1.189-1.96 1.34-4.29 2.09-6.63 2.09-1.77 0-3.48-.42-5.07-1.239-.45-.23-.87-.49-1.28-.761-.01-.02-.01-.02-.02-.02-2.9-2.03-4.79-5.4-4.95-8.94A11.527 11.527 0 0 1 23.92 8.73c1.81-1.13 3.91-1.72 6.09-1.72 2 0 3.95.51 5.66 1.471 3.59 2.049 5.83 5.879 5.83 10.009z"
      fill="#fff"
    />
    <Path
      xmlns="http://www.w3.org/2000/svg"
      d="M37.19 29.79c1.69.37 3.36.88 4.97 1.51-1.25.25-2.45.67-3.57 1.24-4.25 2.2-7.14 6.51-7.54 11.26-.41 4.82 1.75 9.59 5.64 12.46.15.11.3.221.45.32a61.812 61.812 0 0 1-28.17-3.29A3.005 3.005 0 0 1 7 50.47v-6.96c0-3.77 1.89-7.229 5.07-9.27 3.26-2.09 6.82-3.58 10.6-4.42.38.25.77.479 1.18.689 1.88.971 3.89 1.46 5.98 1.46 1.73 0 3.46-.35 5.08-1a14.4 14.4 0 0 0 2.28-1.179z"
      fill="#fff"
    />
    <Path
      xmlns="http://www.w3.org/2000/svg"
      d="M51.33 34.8c3.5 2.17 5.67 6.08 5.67 10.19 0 4.45-2.55 8.64-6.51 10.68-1.66.85-3.55 1.311-5.46 1.311-.39 0-.78-.021-4.33-.801l-2.82-1.529c-3.33-2.46-5.19-6.551-4.84-10.681.35-4.069 2.82-7.77 6.47-9.649 1.67-.86 3.56-1.311 5.48-1.311.39 0 .79.021 1.17.07 1.85.16 3.62.75 5.17 1.72zM53 44.99c0-.55-.45-1-1-1h-6v-6c0-.55-.45-1-1-1s-1 .45-1 1v6h-6c-.55 0-1 .45-1 1 0 .56.45 1 1 1h6v6c0 .56.45 1 1 1s1-.44 1-1v-6h6c.55 0 1-.44 1-1z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
