export default [
  { name: "player", x: 88, y: 0, width: 40, height: 66 },
  { name: "spring_open", x: 114, y: 71, width: 33, height: 51 },
  { name: "spring_closed", x: 81, y: 105, width: 33, height: 18 },
  { name: "block", x: 0, y: 0, width: 79, height: 23 },
  { name: "cloud", x: 0, y: 28, width: 77, height: 24 },
  { name: "wood", x: 0, y: 58, width: 76, height: 12 },
  { name: "wood_broken", x: 0, y: 71, width: 76, height: 14 },
  { name: "wood_falling", x: 0, y: 86, width: 77, height: 29 },
  { name: "ice", x: 0, y: 122, width: 77, height: 21 },
  { name: "ice_broken", x: 0, y: 145, width: 76, height: 24 },
];
