import { sound } from "@pixi/sound";

const music = [];

export const setupSounds = (items) => {
	for (let [name, file] of items) sound.add(name, file);
};
let musicCount = 0;
export const setupMusic = (items) => {
	musicCount = items.length;
	for (let k in items)
		sound.add("music" + k, {
			url: items[k],
			singleInstance: true,
			volume: 0.1,
		});
};

export const play = (name) => {
	sound?.play(name);
};

let musicIndex = -1;
export const playNextMusic = () => {
	musicIndex++;
	if (musicIndex >= musicCount) musicIndex = 0;

	sound?.play("music" + musicIndex, () => {
		playNextMusic();
	});
};

export const resumeMusic = () => {
	if (musicIndex < 0) playNextMusic();
	else sound?.resume("music" + musicIndex);
};

export const pauseMusic = () => {
	sound?.pause("music" + musicIndex);
};

export const muteMusic = async (muted) => {};
